<script setup>
useHead({ bodyAttrs: { id: 'public' } })
</script>

<template>
  <header>
    <div class="header-wrapper">
      <div class="header-left">
      </div>

      <NuxtLink to="/" class="header-logo">
        <NuxtImg src="/logo.png" class="lg:w-20 xl:w-32" />
      </NuxtLink>

      <div class="header-right">
      </div>
    </div>

  </header>

  <slot></slot>

  <footer>
  </footer>
</template>